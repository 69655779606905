<!--<app-loading-spinner [loading]="isLoading" [httpLoading]="httpLoading" [overlay]="true"></app-loading-spinner>-->
<header>
  <div class="container">
    <div class="row">
      <div class="col-8">
        <a class="masthead col-8" routerLink="/">
          <img class="logo" src="../assets/img/Senate Logo_White.png" />
        </a>
      </div>
      <div class="col-4 bottom-right">
        <a href="https://facebook.com/masssenate/" target="_blank">
          <fa-icon class="icon" [icon]="faFacebook" size="3x"></fa-icon>
        </a>
        <a href="https://instagram.com/ma_senate/" target="_blank">
          <fa-icon class="icon" [icon]="faInstagram" size="3x"></fa-icon>
        </a>
        <a href="https://threads.net/@ma_senate" target="_blank">
          <fa-icon class="icon" [icon]="faThreads" size="3x"></fa-icon>
        </a>
        <a href="https://linkedin.com/company/massachusetts-state-senate/" target="_blank">
          <fa-icon class="icon" [icon]="faLinkedin" size="3x"></fa-icon>
        </a>
      </div>
    </div>
  </div>
</header>
<main>
  <router-outlet>
  </router-outlet>
</main>
<div class="footer alert mt-5 mb-0 fixed-bottom" role="alert">
  <div class="container">
    <div class="row">
      <div class="col-12">
        Disclaimer: Employees may log in to the employee portal using their LIS provided username and confidential password. The employee portal contains certain personal information specific to the user, and use of the employee portal is subject to the Senate’s Information Technology Polices, which are included in the Employee Handbook. The information contained in the employee portal is made available to Senate employees for informational purposes only and does not constitute legal, financial, professional, or any other type of advice or counsel. Please contact the appropriate office with respect to any questions you may have.
      </div>
    </div>
  </div>
</div>
