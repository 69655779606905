import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedModule } from '../shared/shared.module';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { GlobalErrorHandler } from './error-handling/global-error-handler';
import { PostLoginComponent } from './post-login/post-login.component';

@NgModule({
  declarations: [
    UnauthorizedComponent,
    PostLoginComponent
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    HttpClientModule,
    SharedModule
  ],
  exports: [
  ],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandler }
  ]
})
export class CoreModule { }
