import { Component, OnInit } from '@angular/core';
import { MsalAuthService } from '../services/msal.service';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent implements OnInit {

  constructor(private msalAuthService: MsalAuthService) { }

  ngOnInit() {
  }

  logout() {
    this.msalAuthService.msalLogout();
  }

  switchUser() {
    this.msalAuthService.msalLogin();
  }

}
