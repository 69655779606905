import { Component, OnInit } from '@angular/core';
import { SiteAlert } from '../models/site-alert';
import { faBell } from '@fortawesome/free-regular-svg-icons';
import { Router } from '@angular/router';
import { SiteAlertService } from '../services/site-alert.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-alert-banner',
  templateUrl: './alert-banner.component.html',
  styleUrls: ['./alert-banner.component.scss']
})
export class AlertBannerComponent implements OnInit {

  constructor(private service: SiteAlertService, private router: Router) { }

  siteAlerts$: Observable<SiteAlert[]>;
  SITE_ALERT_MAX = 3;
  faBell = faBell;

  ngOnInit() {
    this.siteAlerts$ = this.service.getSiteAlerts$();
  }

  onAlertClick(alert: SiteAlert) {
    this.router.navigate(['alert-detail'], {
      state: {
        alert
      }
    });
  }
}
