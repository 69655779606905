import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';
import { linkPipe } from './pipes/link.pipe';
import { TrustHtmlPipe } from './pipes/trust-html.pipe';
import { FormsModule } from '@angular/forms';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { FontFamilyPipe } from './pipes/font-family.pipe';
import { EasternDatetimePipe } from './pipes/eastern-datetime.pipe';
import { ContactCardComponent } from './contact-card/contact-card.component';

@NgModule({
  declarations: [
    linkPipe,
    TrustHtmlPipe,
    FontFamilyPipe,
    EasternDatetimePipe,
    ContactCardComponent
  ],
  providers: [
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    NgbTypeaheadModule,
    RouterModule,
    FormsModule
  ],
  exports: [
    linkPipe,
    TrustHtmlPipe,
    FontFamilyPipe,
    EasternDatetimePipe,
    ContactCardComponent
  ]
})
export class SharedModule { }
