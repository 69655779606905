<div class="alert-banner">
  <div class="alert alert-item my-3 text-center" role="alert" *ngFor="let alert of siteAlerts$ | async">
    <a *ngIf="alert.link" href="{{alert.link}}" target="_blank">
      <div><fa-icon [icon]="faBell"></fa-icon>&nbsp;<span>{{alert.bannerText}}</span></div>
    </a>
    <div *ngIf="!alert.link"><fa-icon [icon]="faBell"></fa-icon>&nbsp;<span>{{alert.bannerText}}</span></div>
    <!--<a *ngIf="!alert.link && alert.html" (click)="onAlertClick(alert)">
    <div class=""><fa-icon [icon]="faBell"></fa-icon>&nbsp;<span>{{alert.bannerText}}</span></div>
  </a>-->
  </div>
</div>

