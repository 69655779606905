import { HttpClient } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector, isDevMode } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandler implements ErrorHandler {

  constructor(private injector: Injector, private http: HttpClient) { }

  handleError(error: Error): void {
    const toastr = this.injector.get(ToastrService);

    if (isDevMode()) {
      console.error(error);
    }

    toastr.error('Something unexpected happened.');

    this.backendLogging(error);
  }

  backendLogging(error: Error) {
    // Send error to backend for logging. Only send error not
    // from http response.
    if (!error.name || error.name !== 'HttpErrorResponse') {
      const body = {
        message: error.message,
        stack: error.stack
      } as Error;
      this.http.post('api/logging', body).subscribe();
    }
  }
}
