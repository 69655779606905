  <div class="row">
    <div class="align-items-center col-12 d-flex authorize-content">
      <div class="m-auto p-4 px-5 bg-white">
        <div class="text-center w-100">
          <!--<h1 class="mb-3">
            Redirecting...
          </h1>-->
        </div>
      </div>
    </div>
  </div>
