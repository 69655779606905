import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MsalAuthService } from '../services/msal.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private msalAuthService: MsalAuthService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.msalAuthService.isAuthenticated()) {
      return this.msalAuthService.isHouseUser().then(result => {
        if (result) {
          return true;
        } else {
          this.router.navigate(['/', 'unauthorized']);
          return false;
        }
      });
    } else {
      this.msalAuthService.msalLogin();
      return false;
    }
  }
}
