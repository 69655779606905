<div class="container">
  <div class="col-12 row align-items-center">
    <h1>
      Unauthorized Access
    </h1>
  </div>
  <div class="row">
    <button class="btn btn-primary" (click)="switchUser()">Switch User</button>
  </div>
</div>
