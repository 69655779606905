import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SiteAlert } from '../models/site-alert';

@Injectable({
  providedIn: 'root'
})
export class SiteAlertService {

  constructor(private http: HttpClient) { }

  public getSiteAlerts$() {
    return this.http.get<SiteAlert[]>('api/siteAlert');
  }
}
