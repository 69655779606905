import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { faFacebook, faInstagram, faLinkedin, faThreads } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
  faFacebook = faFacebook;
  faInstagram = faInstagram;
  faLinkedin = faLinkedin;
  faThreads = faThreads;
  constructor(private authService: MsalService) { }

  ngOnInit() {
    this.authService.handleRedirectObservable().subscribe({
      next: (result) => {
        // If the login was successful, set the active account
        if (result && result.account) {
          this.authService.instance.setActiveAccount(result.account);
        }
      },
      error: (error) => console.error(error)
    });
  }
}
