import { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';
import { InteractionType, BrowserCacheLocation, Configuration, RedirectRequest } from '@azure/msal-browser';


export const environment = {
  production: true,
  loginRequest: {
    scopes: ['openid', 'profile'],
    redirectUri: encodeURI(location.origin + '/post_login'),
    prompt: 'select_account'
  } as RedirectRequest,
  msalConfig: {
    auth: {
      clientId: 'ca0c3b3b-91d1-49d0-86ad-058444d2043f',
      authority: 'https://login.microsoftonline.com/malegislature.onmicrosoft.com',
      redirectUri: encodeURI(location.origin + '/post_login') // This is your redirect URI
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage, // This configures where your cache will be stored
    }
  } as Configuration,
  msalGuardConfig: {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scope: ['user.read', 'openid', 'profile']
    }
  } as MsalGuardConfiguration,

  msalIntercepterConfig: {
    interactionType: InteractionType.Redirect,
    protectedResourceMap: new Map<string, Array<string> | null>([
      ['https://graph.microsoft.com/v1.0/me', ['user.read']],
      ['/api/*', ['api://ca0c3b3b-91d1-49d0-86ad-058444d2043f/api']]
    ])
  } as MsalInterceptorConfiguration,
  whitelistEmails: [],
  portalAccessGroupId: 'da37ab79-28ce-46a7-8609-1619c0a02d58'
};
