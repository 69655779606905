import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { environment } from '../../../environments/environment';
import { firstValueFrom } from 'rxjs';
import { AccountInfo } from '@azure/msal-browser';

@Injectable({
  providedIn: 'root'
})
export class MsalAuthService {

  constructor(private authService: MsalService, private http: HttpClient) {
  }

  msalLogin() {
    this.authService.loginRedirect();
  }

  msalLogout() {
    this.authService.logout();
  }

  acquireToken() {
    return this.authService.acquireTokenSilent(environment.loginRequest);
  }

  isAuthenticated() {
    return !!this.authService.instance.getActiveAccount();
  }

  isHouseUser() {
    return firstValueFrom(this.authService.acquireTokenSilent({
      scopes: ['openid', 'User.Read']
    })).then(result => {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + result?.accessToken
        })
      };

      const postData = {
        groupIds: [environment.portalAccessGroupId] // House Portal Access Group ID
      };
      return firstValueFrom(this.http.post('https://graph.microsoft.com/v1.0/me/checkMemberGroups', postData, httpOptions)).then(response => {
        // Validate result
        return Object.values(response as Record<string, any[]>)[1].length !== 0;
      });
    }, (error) => {
      console.log(error);
      return false;
    });
  }
}
